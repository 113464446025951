<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  }
}
</script>
<style lang="less">
body{
  font-family: "Microsoft YaHei",Arial, sans-serif,"PingFang SC";
}
*{
  margin: 0;
  padding: 0;
}
</style>