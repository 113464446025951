import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/home.vue'),
    redirect: '/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/index/index.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: 'scheme',
        component: () => import('@/views/scheme/scheme.vue'),
        meta: {
          title: '方案'
        }
      },
      {
        path: 'detail',
        component: () => import('@/views/scheme/detail.vue'),
        meta: {
          title: '方案'
        }
      },
      {
        path: 'serve',
        component: () => import('@/views/serve/serve.vue'),
        meta: {
          title: '服务'
        }
      },
      {
        // 招聘
        path: 'about',
        component: () => import('@/views/about/aboutDetail.vue'),
        meta: {
          title: '关于'
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
router.afterEach(route => {
  window.scroll(0, 0)
})
export default router
